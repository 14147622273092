import React, { useState, useEffect } from 'react';
import Email from 'components/Email';
import './App.css';

import './fonts/Baloo2-Regular.ttf';

export default function App() {
  const calculateTimeLeft = () => {
    const date = new Date();
    const utc = date.getTime() + date.getTimezoneOffset();
    const newDate = new Date(utc + 3600000 * 2);

    const difference = +new Date('2022-05-11') - +newDate;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: formatNumber(
          Math.floor((difference / (1000 * 60 * 60)) % 24),
          2,
        ),
        minutes: formatNumber(Math.floor((difference / 1000 / 60) % 60), 2),
        seconds: formatNumber(Math.floor((difference / 1000) % 60), 2),
      };
    }

    return timeLeft;
  };

  const formatNumber = (number, size) => {
    let string = number + '';
    while (string.length < size) string = '0' + string;
    return string;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{' '}
      </span>,
    );
  });

  return (
    <div className="App">
      <div className="timer">
        {timerComponents.length ? (
          timerComponents
        ) : (
          <span>We lost deadline!</span>
        )}
      </div>
      <h1>barva group</h1>
      <Email email={'office@barvagroup.com'} />
      <span className="time">2020</span>
    </div>
  );
}
