import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function Email({ email }) {
  const width = useWindowWidth();

  function useWindowWidth() {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
    });
    return width;
  }

  return (
    <>
      {email && width > 767 ? (
        <CopyToClipboard text={email}>
          <span className="emailAddress">{email}</span>
        </CopyToClipboard>
      ) : (
        <span className="emailAddress">
          <a href={'mailto:' + email}>{email}</a>
        </span>
      )}
    </>
  );
}
